<template>
  <div class="roleManage">
    <div class="page-title">角色管理</div>
    <div class="row-me row-center flex-wrap mT20">
      <div>模糊搜索</div>
      <div class="w300 mL10">
        <el-input placeholder="请输入角色名称" style="width: 100%;" clearable v-model="name"></el-input>
      </div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
    </div>
    <div class="row-me row-center mT20">
      <div class="btn-blue w150 btn-height40" @click="clickAdd">添加角色</div>
    </div>
    <div class="column-me flex1 overflow-auto mT20">
      <div class="flex1 overflow-auto">
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style" height="100%">
          <el-table-column type="index" label="序号" width="100" align="center"/>
          <el-table-column prop="roleId" label="角色ID" align="center" show-overflow-tooltip
                           width="200"></el-table-column>
          <el-table-column prop="roleName" label="角色名称" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="250" fixed="right">
            <template #default="scope">
              <div class="row-me center-all" v-if="!scope.row.admin">
                <div class="blue-text mL20" @click="clickAuth(scope.row)">分配权限</div>
                <div class="blue-text mL20" @click="clickEdit(scope.row)">编辑</div>
                <el-popconfirm title="您确认删除吗？" width="250" @confirm="clickDelete(scope.row)">
                  <template #reference>
                    <div class="red-text mL20">删除</div>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="title" v-model="visibleAdd" width="600px" top="10vh" :destroy-on-close="true">
      <div>
        <div class="row-me center-all mT30">
          <div>角色名称：</div>
          <div class="w350">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="content"></el-input>
          </div>
        </div>
        <div class="w100Per row-me row-center flex-end1 mT50">
          <div class="btn-blank w100 btn-height40 mR20" @click="visibleAdd=false">取消</div>
          <div class="btn-blue w100 btn-height40" @click="clickSave">确定</div>
        </div>
      </div>
    </el-dialog>


    <el-drawer title="角色权限配置" v-model="visibleAuth" direction="rtl" :destroy-on-close="true">
      <div class="auth-view column-me">
        <div class="font16 font-bold mL30">请选择权限</div>
        <div class="flex1 overflow-auto pLR30 mTB20">
          <el-tree ref="tree" :data="dataList" node-key="id" show-checkbox default-expand-all
                   :default-checked-keys="hasPermissionChildNode" :props="defaultProps">
          </el-tree>
        </div>
        <div class="h60 w100Per row-me row-center border-top flex-end1">
          <div class="btn-blank w100 btn-height40 mR20" @click="visibleAuth=false">取消</div>
          <div class="btn-blue w100 btn-height40 mR20" @click="clickSaveAuth">保存</div>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      name: '',
      tableData: [],
      pageNo: 1,
      total: 0,
      title: '新增角色',
      visibleAdd: false,
      detailItem: null,
      content: '',
      visibleAuth: false,
      dataList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      hasPermissionChildNode: [], //剥离作为父节点的权限id
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.clickSearch()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.name = ''
      this.clickSearch()
    },
    clickAdd() {
      this.title = '新增角色'
      this.content = ''
      this.detailItem = null
      this.visibleAdd = true
    },
    clickEdit(item) {
      this.title = '编辑角色'
      this.content = item.roleName
      this.detailItem = this.$method.deepClone(item)
      this.visibleAdd = true
    },
    clickDelete(item) {
      this.$delete('/system/role/' + item.roleId, {}).then((res) => {
        if (res) {
          this.$message.success('删除成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickSave() {
      if (this.$method.isEmpty(this.content)) {
        this.$message.error("请输入角色名称")
        return
      }
      if (this.detailItem) {
        this.detailItem.roleName = this.content
        this.$put('/system/role', this.detailItem).then((res) => {
          if (res) {
            this.$message.success('操作成功')
            this.content = ''
            this.getData()
            this.visibleAdd = false
          }
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.detailItem = {
          roleName: this.content,
          "roleKey": this.content,
          "roleSort": "1",
          "dataScope": 1,
          "menuIds": [],
        }
        this.$post('/system/role', this.detailItem).then((res) => {
          if (res) {
            this.$message.success('操作成功')
            this.content = ''
            this.clickSearch()
            this.visibleAdd = false
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    getData() {
      this.$get('/system/role/list', {
        roleName: this.name,
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickAuth(item) {
      this.detailItem = this.$method.deepClone(item)
      this.hasPermissionChildNode = item.menuIds
      this.getTree()
      this.visibleAuth = true
    },
    // 权限树
    getTree() {
      this.$get('/system/menu/treeselect', {}).then((res) => {
        if (res) {
          this.dataList = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickSaveAuth() {
      this.detailItem.menuIds = this.$refs.tree.getCheckedKeys()
      this.$put('/system/role', this.detailItem).then((res) => {
        if (res) {
          this.$message.success('保存成功')
          this.getData()
          this.visibleAuth = false
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.roleManage {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .auth-view {
    width: 400px;
    height: 100%;
  }
}
</style>
